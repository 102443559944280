<template>
  <!-- Diálogo de Carregamento -->
  <v-dialog v-model="loading" max-width="200" persistent>
    <v-card elevation="12" class="text-center" color="white">
      <v-card-text>
        <v-progress-circular indeterminate color="primary" size="20" class="pt-10"></v-progress-circular>
        <div class="loading-text">Carregando...</div>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- Diálogo de Informações do Simulado -->
  <v-dialog v-model="showQuestionResetDialog" max-width="650">
    <v-card class="pa-6 elevation-4" style="background: #f5f5f5; border-radius: 15px;">
      <v-card-title class="headline text-center" style="color: #3f51b5;">
        <v-icon left>mdi-information-outline</v-icon>
        <strong>Simulado</strong>
      </v-card-title>
      <v-card-text>
        <p class="text-body-1" style="font-size: 16px; color: #333;">
          <strong>Sobre o Simulado.</strong>
        </p>
        <v-divider></v-divider>
        <v-list dense>
          <v-list-item v-for="(item, index) in [
            'Tempo máximo: Simulado 4 horas / Teórico-prático 1 hora.',
            'Não será possível repetir questões.',
            'Não será possível repetir o simulado feito.',
            'O Gabarito será disponibilizado posteriormente, na aba Gabarito Simulados'
          ]" :key="index">
            <v-list-item-icon>
              <v-icon>mdi-checkbox-marked-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-body-2" style="word-wrap: break-word; white-space: normal;">
                {{ item }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="closeSuggestionDialog" color="primary" rounded>Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Diálogo de Salvamento -->
  <v-dialog v-model="resetting" max-width="400" persistent>
    <v-card>
      <v-card-title>Salvando dados.</v-card-title>
      <v-card-text>
        Aguarde enquanto seus dados estão sendo salvos...
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- Primeiro Container: Simulado Normal -->
  <v-container v-if="quizstart !== true">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card elevation="1">
          <v-card-title class="headline text-center font-weight-bold pl-10 pt-10 mb-0" style="color: #282828; white-space: normal; word-break: break-word; overflow-wrap: break-word;">
            Simulados {{ String(dbName) }}
            <v-btn
              icon
              v-on="on"
              @click="openQuestionResetDialog"
              class="ml-auto mb-3"
              style="padding: 4px; border-radius: 4px; max-width: 30px; max-height: 30px;"
            >
              <v-icon color="blue" size="18">mdi-information</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text v-if="selectedMode === 'Tutor'" class="pl-10 pr-10 pt-0 mt-1 mx-auto">
            Para iniciar escolha um dos simulados disponíveis.
          </v-card-text>
          <v-select
            v-model="selectedTheme"
            :items="loadThemes"
            label="Escolha o Simulado"
            class="pl-10 pr-10 pt-3 mx-auto"
            placeholder="Escolha o Simulado."
            chips
            deletable-chips
            @change="handleThemeSelectionChange"
          ></v-select>
          <v-card-actions class="d-flex justify-center pb-5" elevation="0">
            <v-btn
              :disabled="!selectedTheme"
              style="width: 200px; height: 40px; font-size: 14px; color: white; border-radius: 10px; text-transform: none;"
              @click="startQuiz"
              color="primary"
              elevation="0"
            >
              Iniciar Simulado
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

  <!-- Segundo Container: Simulado Teórico-Prático -->
  <v-container v-if="quizstart !== true">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card elevation="1">
          <v-card-title class="headline text-center font-weight-bold pl-10 pt-10 mb-0" style="color: #282828; white-space: normal; word-break: break-word; overflow-wrap: break-word;">
            Simulado Teórico-Prático {{ String(dbName) }}
            <v-btn
              icon
              v-on="on"
              @click="openQuestionResetDialog"
              class="ml-auto mb-3"
              style="padding: 4px; border-radius: 4px; max-width: 30px; max-height: 30px;"
            >
              <v-icon color="blue" size="18">mdi-information</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text v-if="selectedMode === 'Tutor'" class="pl-10 pr-10 pt-0 mt-1 mx-auto">
            Para iniciar escolha um dos simulados disponíveis.
          </v-card-text>
          <v-select
            v-model="selectedTheme2"
            :items="loadThemes2"
            label="Escolha o Simulado"
            class="pl-10 pr-10 pt-3 mx-auto"
            placeholder="Escolha o Simulado."
            chips
            deletable-chips
            @change="handleThemeSelectionChange2"
          ></v-select>
          <v-card-actions class="d-flex justify-center pb-5" elevation="0">
            <v-btn
              :disabled="!selectedTheme2"
              style="width: 200px; height: 40px; font-size: 14px; color: white; border-radius: 10px; text-transform: none;"
              @click="startQuiz2"
              color="primary"
              elevation="0"
            >
              Iniciar Simulado
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { onBeforeMount, computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  name: 'QuizSetup',
  props: {
    noqdName: Number, // Número total de questões feitas
    noqName: Number, // Número total de questões disponíveis
    loadThemes: Array,
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();

    // Variáveis reativas
    const showChallengeDialog = ref(false);
    const quizstart = ref(false);
    const selectedNumberOfQuestions = ref(1);
    const selectedTheme = ref(['']);
    const selectedTheme2 = ref(['']);
    const selectedMode = ref('Normal');
    const showQuestionResetDialog = ref(false);
    const resetting = ref(false);

    // Computed properties
    const loading = computed(() => store.getters.loading);
    const dbName = computed(() => store.getters.dbName);
    const noqdName = computed(() => store.getters.noqdName);
    const noqName = computed(() => store.getters.noqName);

    const loadThemes = computed(() => {
      const themes = new Set(['']);
      const items = store.getters.loadedSimulados;

      items.forEach((question) => {
        if (question.simuladoNome !== '') {
          themes.add(question.simuladoNome);
        }
      });

      return Array.from(themes);
    });

    const loadThemes2 = computed(() => {
      const themes = new Set(['']);
      const items = store.getters.loadedSimulados2; // Supondo que você tenha um getter diferente para o segundo container

      items.forEach((question) => {
        if (question.simuladoNome !== '') {
          themes.add(question.simuladoNome);
        }
      });

      return Array.from(themes);
    });

    // Watchers
    watch(dbName, (newDbName) => {
      if (newDbName && String(newDbName) === 'TEMFC') {
        showChallengeDialog.value = true;
      }
    });

    // Lifecycle Hooks
    onBeforeMount(() => {
      localStorage.clear();
      document.cookie = '';
      store.dispatch('loadSimulados');
      store.dispatch('loadSimulados2'); // Carregar dados para o segundo container

      if (String(dbName.value) === 'TEMFC') {
        showChallengeDialog.value = true;
      }
    });

    // Métodos
    const openQuestionResetDialog = () => {
      showQuestionResetDialog.value = true;
    };

    const closeSuggestionDialog = () => {
      showQuestionResetDialog.value = false;
    };

    const saveQuestionReset = () => {
      resetting.value = true;
      store.dispatch('saveQuestionReset')
        .then(() => {
          setTimeout(() => {
            store.dispatch('loadItems');
          }, 1000);
          setTimeout(() => {
            resetting.value = false;
          }, 5000);
        })
        .catch((error) => {
          console.error('Erro ao salvar item:', error);
        });
      closeSuggestionDialog();
    };

    const handleThemeSelectionChange = (newVal) => {
      selectedTheme.value = newVal;

      if (selectedTheme.value.includes('Todos') && selectedTheme.value.length > 1) {
        const todosIndex = selectedTheme.value.indexOf('Todos');
        selectedTheme.value.splice(todosIndex, 1);
      } else if (selectedTheme.value.length === 0) {
        selectedTheme.value = ['Todos'];
      }

      console.log('Tema selecionado:', selectedTheme.value);
    };

    const handleThemeSelectionChange2 = (newVal) => {
      selectedTheme2.value = newVal;

      if (selectedTheme2.value.includes('Todos') && selectedTheme2.value.length > 1) {
        const todosIndex = selectedTheme2.value.indexOf('Todos');
        selectedTheme2.value.splice(todosIndex, 1);
      } else if (selectedTheme2.value.length === 0) {
        selectedTheme2.value = ['Todos'];
      }

      console.log('Tema selecionado 2:', selectedTheme2.value);
    };

    const startQuiz = async () => {
      let filteredItems = [];

      store.getters.loadedSimulados.forEach((item) => {
        if (selectedTheme.value.includes('Todos')) {
          filteredItems.push(item);
        } else if (selectedTheme.value.includes(item.simuladoNome)) {
          filteredItems.push(item);
        }
      });

      emit('start-quiz', {
        items: filteredItems,
        mode: selectedMode.value,
      });

      router.push({
        name: 'QuestionsQuizSimulado',
        query: {
          mode: selectedMode.value,
          items: JSON.stringify(filteredItems),
        },
      });
    };

    const startQuiz2 = async () => {
      let filteredItems2 = [];

      store.getters.loadedSimulados2.forEach((item) => {
        if (selectedTheme2.value.includes('Todos')) {
          filteredItems2.push(item);
        } else if (selectedTheme2.value.includes(item.simuladoNome)) {
          filteredItems2.push(item);
        }
      });

      emit('start-quiz', {
        items: filteredItems2,
        mode: selectedMode.value,
      });

      router.push({
        name: 'QuestionsQuizSimuladoPratico',
        query: {
          mode: selectedMode.value,
          items: JSON.stringify(filteredItems2),
        },
      });
    };

    return {
      quizstart,
      showChallengeDialog,
      showQuestionResetDialog,
      selectedNumberOfQuestions,
      selectedTheme,
      selectedTheme2,
      selectedMode,
      resetting,
      dbName,
      noqdName,
      noqName,
      openQuestionResetDialog,
      closeSuggestionDialog,
      saveQuestionReset,
      handleThemeSelectionChange,
      handleThemeSelectionChange2,
      startQuiz,
      startQuiz2,
      loadThemes,
      loadThemes2,
      loading,
    };
  },
};
</script>

<style scoped>
/* Estilos opcionais */
</style>