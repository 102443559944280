import { createRouter, createWebHashHistory } from 'vue-router'
import WebApp from '../views/WebApp.vue'
import Login from '../views/Login.vue'
import Profile from '../views/Profile.vue'
import AboutView from '../views/AboutView.vue'
import store  from '../store/index'
import FlashCards from '../views/FlashCards.vue'
import Results from '../views/Results.vue'
import QuestionsAnswers from '../views/QuestionsAnswers.vue'
import QuestionsResults from '../views/QuestionsResults.vue'
import Questions from '../views/Questions.vue'
import QuestionsUpdated from '../views/QuestionsUpdated.vue'
import QuestionsQuiz from '../views/QuestionsQuiz.vue'
import QuestionsChallenge from '../views/QuestionsChallenge.vue'
import QuestionsQuizChallenge from '../views/QuestionsQuizChallenge.vue'
import QuestionsQuizSimulado from '@/views/QuestionsQuizSimulado.vue'
import QuestionsQuizSimuladoPratico from '@/views/QuestionsQuizSimuladoPratico.vue'

import QuestionsAnswersChallenge from '../views/QuestionsAnswersChallenge.vue'
import QuestionsSimulado from '../views/QuestionsSimulado.vue'




import QuestionsAdmin from '../views/QuestionsAdmin.vue'
import Decks from '../views/Decks.vue'




const authGuard = (to, from, next) => {
  if (store.state.user) {

    // Se o usuário estiver autenticado, permita a navegação
    next()
  } else {
    // Se o usuário não estiver autenticado, redirecione para a página de login,
    // mas salve a rota para a qual ele estava tentando acessar para redirecioná-lo
    // de volta após o login bem-sucedido
    next({
      path: '/',
      query: { redirect: to.fullPath }
    })
  }
}

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/webapp/questions',
    redirect: '/webapp/questionsupdated'
  },
  {
    path: '/webapp/questionsv2',
    redirect: '/webapp/questionsupdated'
  },
  {
    path: '/webapp',
    name: 'WebApp',
    component: WebApp,
    beforeEnter: authGuard,
    children: [
      {
        path: 'home',
        component: Decks
      },
      {
        path: 'decks',
        component: Decks
      },
      {
        path: 'questionsv2',
        component: Questions
      },
      {
        path: 'questionsupdated',
        component: QuestionsUpdated
      },
      {
        path: 'questionssimulado',
        component: QuestionsSimulado
      },
      {
        path: 'questionschallenge',
        component: QuestionsChallenge
      },
      {
        path: 'questionsquiz',
        name: 'QuestionsQuiz', // Certifique-se de que o nome está igual ao usado no redirecionamento
        component: QuestionsQuiz
      },
      {
        path: 'questionsquizsimulado',
        name: 'QuestionsQuizSimulado', // Certifique-se de que o nome está igual ao usado no redirecionamento
        component: QuestionsQuizSimulado
      },
      {
        path: 'questionsquizsimuladopratico',
        name: 'QuestionsQuizSimuladoPratico', // Certifique-se de que o nome está igual ao usado no redirecionamento
        component: QuestionsQuizSimuladoPratico
      },
      {
        path: 'questionsquizchallenge',
        name: 'QuestionsQuizChallenge', // Certifique-se de que o nome está igual ao usado no redirecionamento
        component: QuestionsQuizChallenge
      },
      {
        path: 'questionsresults',
        component: QuestionsResults
      },
      {
        path: 'questionsanswers',
        component: QuestionsAnswers
      },
      {
        path: 'questionsanswerschallenge',
        name: 'QuestionsAnswersChallenge', // Certifique-se de que o nome está igual ao usado no redirecionamento
        component: QuestionsAnswersChallenge
      },
      {
        path: 'questionsadmin',
        component: QuestionsAdmin
      },
      {
        path: 'about',
        component: AboutView
      },
      {
        path: 'profile',
        component: Profile
      },
      {
        path: 'flashcards',
        component: FlashCards
      },
      {
        path: 'results',
        component: Results
      },
  
      
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  base: process.env.BASE_URL,
  routes
})

// Adicionar o navigation guard para revalidar o token antes de cada navegação
router.beforeEach(async (to, from, next) => {
  // Verifica se a rota de destino é a página de login
  if (to.path === '/') {
    return next(); // Permite o acesso à página de login sem revalidar o token
  }

  try {
    // Tenta revalidar o token antes de permitir a navegação
    await store.dispatch('revalidateToken');
    next(); // Se a revalidação for bem-sucedida, permite a navegação
  } catch (error) {
    console.error('Erro ao revalidar o token:', error);
    // Redireciona para a página de login se o token for inválido
    next({
      path: '/',
      query: { redirect: to.fullPath } // Mantém a página que o usuário queria acessar
    });
  }
});


export default router  