<template>
    <v-app>
      <!-- Barra de navegação -->
      <v-app-bar app color="#3474BE">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-img src="../assets/logo-21.png" alt="Logo do MedExperts" height="30" style="margin-top: 0px;" class="ml-4"></v-img>
        <v-spacer class="d-none d-md-flex"></v-spacer> <!-- Adicione um espaçador apenas em telas médias ou maiores -->
        <v-spacer class="d-none d-md-flex"></v-spacer> <!-- Adicione um espaçador apenas em telas médias ou maiores -->
        <v-spacer class="d-none d-md-flex"></v-spacer> <!-- Adicione um espaçador apenas em telas médias ou maiores -->
        <v-spacer class="d-none d-md-flex"></v-spacer> <!-- Adicione um espaçador apenas em telas médias ou maiores -->
        <v-spacer class="d-none d-md-flex"></v-spacer> <!-- Adicione um espaçador apenas em telas médias ou maiores -->
        <v-spacer class="d-none d-md-flex"></v-spacer> <!-- Adicione um espaçador apenas em telas médias ou maiores -->
        <v-spacer class="d-none d-md-flex"></v-spacer> <!-- Adicione um espaçador apenas em telas médias ou maiores -->

        <!-- Adicionando campo para o nome da pessoa à direita -->
        <v-spacer></v-spacer>
        <v-toolbar-items>
            <v-icon class="mr-2 mt-5">mdi-account-circle</v-icon>
          <v-toolbar-title class="mr-10 mt-5" style="font-size: 15px;">{{userName}}</v-toolbar-title>
        </v-toolbar-items>
      </v-app-bar>

      
  
      <!-- Barra lateral (navigation drawer) -->
      <v-navigation-drawer app v-model="drawer" color="#3474BE">

        <v-list dense>
          <v-list-item v-if="['TEMFC', 'AGSUS'].includes(dbName)"  link to="/webapp/questionschallenge">
            <v-list-item-icon>
              <v-icon>mdi-trophy-award</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Desafio 150 Questões</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link to="/webapp/questionsupdated">
            <v-list-item-icon>
              <v-icon>mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Treinar Questões</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="['TEMFC', 'AGSUS'].includes(dbName)" link to="/webapp/questionssimulado">
            <v-list-item-icon>
              <v-icon>mdi-book-open-page-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Simulados</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item link to="/webapp/questionsupdated">
            <v-list-item-icon>
              <v-icon>mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Treinar Questões2</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/webapp/questionsquiz">
            <v-list-item-icon>
              <v-icon>mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Treinar Questões4</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

          <v-list-item link to="/webapp/questionsresults">
            <v-list-item-icon>
              <v-icon>mdi-chart-donut</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Métricas Questões</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          

          <v-list-item link to="/webapp/questionsanswers">
            <v-list-item-icon>
              <v-icon>mdi-book</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Gabarito Simulados</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
<!-- v-if="dbName == 'TEMFC'" -->
          <v-list-item v-if="['TEMFC', 'AGSUS'].includes(dbName)"  link to="/webapp/decks">
            <v-list-item-icon>
              <v-icon>mdi-cards</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Decks Flashcards</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- v-if="dbName == 'TEMFC'" -->
          <!-- Botão para rota "Results" -->
          <v-list-item v-if="['TEMFC', 'AGSUS'].includes(dbName)"   link to="/webapp/results">
            <v-list-item-icon>
              <v-icon>mdi-chart-timeline-variant-shimmer</v-icon>
            </v-list-item-icon>
            <v-list-item-content> 
              <v-list-item-title>Métricas Flashcards</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
  
          <!-- Botão para rota "About" -->
          <v-list-item link to="/webapp/profile">
            <v-list-item-icon>
              <v-icon>mdi-account-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Usuário</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="isAdmin" link to="/webapp/questionsadmin">
            <v-list-item-icon>
              <v-icon>mdi-monitor-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Admin Questões</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
          <!-- Botão para rota "About" -->
          <v-list-item v-if="['TEMFC', 'AGSUS'].includes(dbName)" link to="/webapp/about">
            <v-list-item-icon>
              <v-icon>mdi-information</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Info Flashcards</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        
        
        <!-- Separador -->
        <v-divider></v-divider>
        
        <!-- Botão de logout (fixado na parte inferior) -->
        <v-list-item class="mt-auto" @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-navigation-drawer>
  
      <!-- Conteúdo principal -->
      <v-main>
        <router-view></router-view>
      </v-main>
  
      <!-- Rodapé -->
      <v-footer app color="#3474BE">
        <span>&copy; 2025 MedExperts - 2.7.1 </span>
      </v-footer>
    </v-app>
  </template>
  
  <script>
  
  export default {
    data() {
      return {
        drawer: false,
                componentMounted: false

      };
    },

    beforeMount() {
    // Load data before  mounting
    this.$store.dispatch("checkExistingUser");
    this.$store.dispatch('checkAdmin')
    this.$store.dispatch("loadItems");
    this.$store.dispatch("loadItemsChallenge");


    
    },

    watch: {
    dbName(newDbName) {
        if (['TEMFC', 'AGSUS'].includes(newDbName)) {
           this.$store.dispatch("getCards");
        }
    }
},

    computed:{
      userName: function () {
        // Verifica se this.$store.getters.userName não é undefined ou null
        if (this.$store.getters.userName !== undefined && this.$store.getters.userName !== null) {
            // Verifica se this.$store.getters.userName é um array e possui pelo menos um elemento
            if (Array.isArray(this.$store.getters.userName) && this.$store.getters.userName.length > 0) {
                // Retorna o nome do primeiro elemento do array
                return this.$store.getters.userName[0].firstName;
            } else {
                // Trate o caso em que this.$store.getters.userName é um array vazio
                return '';
            }
        } else {
            // Trate o caso em que this.$store.getters.userName é undefined ou null
            return '';
        }
    },
    isAdmin() {
      return this.$store.getters.admin === 'temfcadmin' || this.$store.getters.admin === 'tegadmin';
    },
    dbName: function () {
          return this.$store.getters.dbName;
       },
    },
    methods: {
      logout() {
        this.$store.dispatch('logout').then(() => {
           this.$router.push('/')
          }); 
             //console.log("Logout executado");
      },
      check() {
        this.$store.dispatch('checkExistingUser').then(() => {
          // this.$router.push('/')
          }); 
             //console.log("Logout executado");
      }
    }
  };
  </script>
  