<template>
    <v-container fluid>

        <!-- Loading Dialog -->
        <v-dialog v-model="loading" max-width="200" persistent>
            <v-card elevation="12" class="text-center" color="white">
                <v-card-text>
                <v-progress-circular indeterminate color="primary" size="20" class="pt-10" ></v-progress-circular>
                <div class="loading-text">Carregando...</div>
                </v-card-text>
            </v-card>
        </v-dialog>


      <v-row v-if="loadResults.length > 0">
        <v-col cols="12">
          
          <v-card>
            <v-card-text>
              <v-row align="center">
                <v-col cols="12" md="6" lg="6">
                  <v-card class="metric-card" style="padding: 0px;">
                    <v-card-text>
                        <v-card-title style="padding-top: 0px;" class="card-title-bold">
                            <span class="card-title-text"> Total Acumulado</span>
                        </v-card-title>
                      <div class="d-flex align-center">
                        <v-progress-circular 
                          :size="150"
                          :width="15"
                          :value=loadResults[0].totalRightPercent
                          color="primary"
                          filled
                        >
                        <h2>{{loadResults[0].totalRightPercent}}%</h2>
                        </v-progress-circular>
                        <div class="text-xs-center ml-12">
                          <h4>Total de Questões Respondidas:</h4>
                          <p>{{ loadResults[0].totalQuestions }}</p>
                          <h4>Total de Acertos ({{loadResults[0].totalRightPercent}}%):</h4>
                          <p>{{ loadResults[0].totalRight }}</p>
                          <h4>Total de Erros ({{loadResults[0].totalWrongPercent}}%):</h4>
                          <p>{{ loadResults[0].totalWrong  }}</p>
                        </div>
                      </div>
                    </v-card-text>
                    <v-card-actions>
                        <small>Nota: O Total Acumulado apresenta métricas referentes a soma de todas as questões realizadas.</small>
                    </v-card-actions>
                  </v-card>
                </v-col>

                <v-col cols="12" md="6" lg="6">
                  <v-card class="metric-card" style="padding: 0px;">
                    <v-card-text>
                        <v-card-title style="padding-top: 0px;" class="card-title-bold">
                            <span class="card-title-text"> Último conjunto de Questões</span>
                        </v-card-title>
                      <div class="d-flex align-center">
                        <v-progress-circular
                          :size="150"
                          :width="15"
                          :value=loadResults[0].lastRightPercent
                          color="success"
                          filled
                        >
                        <h2>{{loadResults[0].lastRightPercent}}%</h2>
                        </v-progress-circular>
                        <div class="text-xs-center ml-12">
                          <h4>Questões respondidas no conjunto de Questões</h4>
                          <p>{{ loadResults[0].lastQuestions }}</p>
                          <h4>Acertos no último conjunto de Questões ({{ loadResults[0].lastRightPercent }}%):</h4>
                          <p>{{ loadResults[0].lastRight }}</p>
                          <h4>Erros no último conjunto de Questões ({{ loadResults[0].lastWrongPercent }}%):</h4>
                          <p>{{ loadResults[0].lastWrong }}</p>
                         </div>
                      </div>
                    </v-card-text>
                    <v-card-actions>
                        <small>Nota: conjunto de Questões realizado em: {{loadResults[0].lastTest }}.</small>
                    </v-card-actions>
                  </v-card>
                </v-col>

                <v-col cols="12" md="12" lg="12">
                  <v-card class="metric-card" style="padding: 0px;">
                    <v-card-text>
                        <v-card-title style="padding-top: 0px;" class="card-title-bold">
                          <v-btn class ="mr-2 mb-3"icon @click="refreshData">
                                <v-icon>mdi-refresh</v-icon>
                          </v-btn>
                        <span class="card-title-text"> Métricas por Tema</span>
                              
                        </v-card-title>
                       <!-- Seção para exibir as estatísticas por tema -->
                       <v-container>
                            <div v-if="loadResults[0].additionalData.every(item => item.themeTotal === '' || item.themeTotal === null)">
                              <v-alert :value="true" color="info" outlined>
                                Nenhuma estatística disponível. Complete novos conjunto de Questões para apresentar métricas individuais por tema.
                              </v-alert>
                            </div>
                            <v-data-table v-else :headers="tableHeaders" :items="loadResults[0].additionalData" class="elevation-1" :mobile-breakpoint="0" :disable-sort="true">
                              <template v-slot:item="{ item }">
                                <tr v-if="item.themeTotal !== '' && item.themeTotal !== null" class="table-row">
                                  <td>{{ item.theme }}</td>
                                  <td>{{ item.themeRight }}</td>
                                  <td>{{ item.themeTotal }}</td>
                                  <td>
                                    <span :class="getPercentageClass(item.themeRight / item.themeTotal * 100)">
                                      {{ (item.themeRight / item.themeTotal * 100).toFixed(2) }}%
                                    </span>
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                            <div class="legend mt-3" >
                              <div class="legend-item">
                                <span class="legend-color excellent"></span>
                                <span class="ml-1 mr-2">Excelente</span>
                                <span class="legend-color good"></span>
                                <span class="ml-1 mr-2">Bom</span>
                                <span class="legend-color average"></span>
                                <span class="ml-1 mr-2">Médio</span>
                                <span class="legend-color to-improve"></span>
                                <span class="ml-1 mr-2">A Melhorar</span>
                              </div>
                            </div>
                          </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <small>Nota: Apenas conjunto de Questões após 31.01.2023 apresentam métricas individuais por tema.</small>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <!-- Add more metric cards -->
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-else justify="center">
        <v-col cols="12" sm="8" md="6">
            <v-card class="text-center" elevation="5">
            <v-card-text>
                <v-icon x-large color="grey lighten-1">mdi-folder-question</v-icon>
                <p class="mt-3 font-weight-bold">Nenhum dado disponível. </p>
                <p class="mt-3 text-left">1. Acesse a Aba "Treinar Questões" para iniciar seu primeiro conjunto de Questões.</p>
                <p class="mt-3 text-left">2. Complete seu primeiro conjunto de Questões para possuir dados disponíveis no Dashboard.</p>
            </v-card-text>
            </v-card>
        </v-col>
        </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    data() {
      return {
        tableHeaders: [
          { title: 'Tema' },
          { title: 'Acertos'},
          { title: 'Total de Questões Realizadas'},
          { title: 'Taxa de Acertos (%)'},

          ],
        user: {
          name: 'John Doe', // Replace with actual user data
          totalQuestions: 100, // Replace with actual user metrics
          totalRight:10,
          totalWrong:20,
          lastQuestions:10,
          lastRight:5,
          lastWrong:5,
          LastTest:"19/12/2023 09:36:38",
          totalRightPercent:"12%",
          totalWrongPercent:"11%",
          lastRightPercent: "20%",
          lastWrongtPercent: "5%",
          // Add other user metrics here
        }
      };
    },

    beforeMount() {
      // Load data before  mounting
      this.$store.dispatch('loadResults',{search: true})     
    },

    computed: {
        loading() {
            return this.$store.getters.loading;
        },
        loadResults: function () {
            return this.$store.getters.loadedResults;
        },
    },
    methods: {
      getPercentageClass(percentage) {
        if (percentage >= 85) return 'excellent';
        if (percentage >= 70) return 'good';
        if (percentage >= 50) return 'average';
        return 'to-improve';
      },
      refreshData() {
        // Chame a ação de carregar resultados novamente
        this.$store.dispatch('loadResults', { search: true });
      }
    }



  };
  </script>
  
  <style>
  .metric-card {
    margin-bottom: 20px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }

  .card-title-bold {
  font-weight: bold;
  }

  .loading-text {
  margin-top: 15px;
  font-weight: bold;
  font-size: 18px;
  }
  .table-row:nth-child(even) {
  background-color: #ffffff;
}

.table-row:hover {
  background-color: #ddd;
}

.v-data-table__wrapper table tbody tr:nth-child(odd) {
  background-color: #f2f2f2;
}
.excellent {
  background-color: rgba(0, 128, 0, 0.700);
  color: white;
  padding: 1px 10px;
  border-radius: 5px;
}

.good {
  background-color: rgba(0, 0, 255, 0.700);
  color: white;
  padding: 1px 10px;
  border-radius: 5px;
}

.average {
  background-color: rgba(255, 255, 0, 0.700);
  color: black;
  padding: 1px 10px;
  border-radius: 5px;
}

.to-improve {
  background-color: rgba(255, 0, 0, 0.700);
  color: white;
  padding: 1px 10px;
  border-radius: 5px;
}
  
  /* Add your custom styles here */
  </style>
  